<template>
	<a-select
		v-model:value="name"
		allow-clear
		:class="props.class ? '' : 'inputSearch'"
		show-search
		:max-tag-count="1"
		:placeholder="`请选择操作人`"
		:default-active-first-option="false"
		:show-arrow="false"
		:filter-option="false"
		:not-found-content="null"
		:options="options"
		@search="getList"
		@change="handleChange"
	/>
</template>
<script setup>
/* eslint-disable */
import { defineComponent, ref, computed, reactive, watch, onMounted, onActivated } from "vue"
import { useStore } from "vuex"

import axios from "@/common/axios"
import api from "@/common/api/api"

const store = useStore()

const props = defineProps({
	diasbled: Boolean,
	class: {
		type: String,
		default: "",
	},
	parentId: {
		type: String,
		default: "",
	},
	defaultId: {
		type: String,
		default: "",
	},
})

const diasbled = ref(true)
const emits = defineEmits(["changeValue"])
const name = ref(null)

const options = ref([])
const data = ref([])
onActivated(() => {
	console.log(props.defaultId)
	name.value = ""
	name.value = props.defaultId ? props.defaultId : ""
	getList()
})
watch(
	() => props.defaultId,
	(value) => {
		name.value = value ? value : ""
	}
)
watch(
	() => props.parentId,
	(value) => {
		getList()
	}
)
const focus = () => {}
const handleChange = (value) => {
	console.log(value)
	let params = null
	params = { value: value }
	emits("changeValue", params)
}
const getList = (value) => {
	let data = {
		page_no: 1,
		page_size: 500,
		name: name.value,
		merchantId: store.getters["user/merchantId"],
		departmentId: props.parentId,
	}
	axios.post(api.getUserList, data).then((res) => {
		if (res.list && res.list.length) {
			let arr = []
			res.list.forEach((item) => {
				arr.push({
					label: item.userName,
					value: item.userName,
				})
			})
			options.value = arr
		} else {
			options.value = []
		}
	})
}
defineExpose({
	getList,
})
/* eslint-enable */
</script>
