<template>
  <a-card>
    <template #title>
      <div class="header">
        <a-button type="primary" @click="backUp" style="margin-right: 10px">
          返回</a-button
        >
      </div>
    </template>
    <a-form
      style="width: 500px; margin: 0 auto"
      ref="merchantRef"
      :model="ruleForm"
      :label-col="state.labelCol"
      :wrapper-col="state.wrapperCol"
    >
      <a-form-item label="应用图标" name="name">
        <a-upload
          style="width: 50px; height: 50px"
          v-model:file-list="file.fileList"
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          action=""
          :before-upload="beforeUpload"
          :custom-request="changeUpload"
        >
          <img
            v-if="file.imageUrl"
            style="width: 100%; height: 100%"
            :src="file.imageUrl"
            alt="avatar"
          />
          <div v-else>
            <loading-outlined v-if="file.loading"></loading-outlined>
            <plus-outlined v-else></plus-outlined>
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入应用名称`,
            trigger: 'blur',
          },
        ]"
        name="name"
        :label="`name`"
      >
        <a-input
          v-model:value="ruleForm.name"
          :placeholder="`请输入应用名称`"
        />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入应用分类`,
            trigger: 'blur',
          },
        ]"
        name="appCategory"
        label="应用分类"
      >
        <a-input
          v-model:value="ruleForm.appCategory"
          :placeholder="`请输入应用分类`"
        />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入code`,
            trigger: 'blur',
          },
        ]"
        name="code"
        :label="`code`"
      >
        <a-input v-model:value="ruleForm.code" :placeholder="`请输入code`" />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入标题`,
            trigger: 'blur',
          },
        ]"
        name="title"
        label="标题"
      >
        <a-input v-model:value="ruleForm.title" :placeholder="`请输入标题`" />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入appKey`,
            trigger: 'blur',
          },
        ]"
        name="appKey"
        label="appKey"
      >
        <a-input
          v-model:value="ruleForm.appKey"
          :placeholder="`请输入appKey`"
        />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入appSecret`,
            trigger: 'blur',
          },
        ]"
        name="appSecret"
        label="appSecret"
      >
        <a-input
          v-model:value="ruleForm.appSecret"
          :placeholder="`请输入appSecret`"
        />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入平台`,
            trigger: 'blur',
          },
        ]"
        name="platform"
        label="platform"
      >
        <a-input
          v-model:value="ruleForm.platform"
          :placeholder="`请输入平台`"
        />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入baseUrl`,
            trigger: 'blur',
          },
        ]"
        name="baseUrl"
        label="baseUrl"
      >
        <a-input
          v-model:value="ruleForm.baseUrl"
          :placeholder="`请输入baseUrl`"
        />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入tags`,
            trigger: 'blur',
          },
        ]"
        name="tags"
        label="tags"
      >
        <a-input v-model:value="ruleForm.tags" :placeholder="`请输入tags`" />
      </a-form-item>
      <a-form-item
        :rules="[
          {
            required: true,
            message: `请输入desc`,
            trigger: 'blur',
          },
        ]"
        name="desc"
        label="desc"
      >
        <a-input v-model:value="ruleForm.desc" :placeholder="`请输入desc`" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
        <a-button
          type="primary"
          style="margin: 15px auto"
          @click="submitAdd('submit')"
          >{{ edit?"保存":"创建" }}</a-button
        >
      </a-form-item>
    </a-form>
  </a-card>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import axios from "@/common/axios";
import api from "@/common/api/api";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();
const route=useRoute()
const state = ref({
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
});
const edit=ref(false)
onMounted(()=>{
    console.log(route.query)
    console.log(history.state.json)
    if(history.state?.json){
        ruleForm.value=JSON.parse(history.state.json)
        console.log(ruleForm.value)
        edit.value=true
    }
})
const ruleForm = ref({
  code: "", //编码
  name: "", //名称
  appCategory: "", //分类
  title: "", //标题
  appKey: "", //appKey
  appSecret: "", //appSecret
  platform: "", //平台
  baseUrl: "", //url
  icon: "", //图标
  tags: "", //标签
  desc: "", //描述
});
const merchantRef = ref();
const submitAdd = () => {
  console.log(ruleForm.value._value);
  let data = {
    app: {
        id:ruleForm.value.id,
      code: ruleForm.value.code, //编码
      name: ruleForm.value.name, //名称
      appCategory: ruleForm.value.appCategory, //分类
      title: ruleForm.value.title, //标题
      appKey: ruleForm.value.appKey, //appKey
      appSecret: ruleForm.value.appSecret, //appSecret
      platform: ruleForm.value.platform, //平台
      baseUrl: ruleForm.value.baseUrl, //url
      icon: ruleForm.value.icon, //图标
      tags: ruleForm.value.tags, //标签
      desc: ruleForm.value.desc, //描述
    },
  };
  console.log(data);
  merchantRef.value
    .validateFields()
    .then((res) => {
      if (res) {
        axios.post(api.appSaveTemplate, data).then(() => {
          message.success("操作成功");
          router.push({ name: "addApplication" });
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
const backUp = () => {
  router.back();
};

//上传功能
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG file!");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  return isJpgOrPng && isLt2M;
};
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const file = ref({
  fileList: [],
  loading: false,
  imageUrl: "",
});
const changeUpload = (info) => {
  file.value.loading = true;
  const formData = new FormData();
  // Get this url from response in real world.
  getBase64(info.file, (base64Url) => {
    file.value.imageUrl = base64Url;
    file.value.loading = false;
    formData.append("file", info.file);
    formData.append("merchantId", store.getters["user/merchantId"]);
    formData.append("bizType", "APP_ICON");
    axios
      .post(api.fileUpload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        ruleForm.value.icon = res.url;
      });
  });
};
</script>
