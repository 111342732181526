<template>
	<a-card>
		<template #title>
			<div class="header">
				<a-button type="primary" @click="backUp">返回</a-button>
			</div>
		</template>
		<a-form
			style="width: 500px; margin: 0 auto"
			ref="merchantRef"
			:model="ruleForm"
			:rules="rules"
			:label-col="labelCol"
			@finish="submitAdd"
			:wrapper-col="wrapperCol"
		>
			<a-form-item label="OMS账号" v-if="OMS" name="userName">
				<a-input v-model:value="ruleForm.userName" />
			</a-form-item>
			<a-form-item label="OMS密码" v-if="OMS" name="password">
				<a-input v-model:value="ruleForm.password" />
			</a-form-item>
			<a-form-item label="应用图标">
				<a-upload
					style="width: 50px; height: 50px"
					v-model:file-list="fileList"
					name="avatar"
					list-type="picture-card"
					class="avatar-uploader"
					:show-upload-list="false"
					action=""
					:before-upload="beforeUpload"
					:custom-request="changeUpload"
				>
					<img v-if="imageUrl" style="width: 50px; height: 50px" :src="imageUrl" alt="avatar" />
					<div v-else>
						<loading-outlined v-if="loading"></loading-outlined>
						<plus-outlined v-else></plus-outlined>
						<div class="ant-upload-text">Upload</div>
					</div>
				</a-upload>
			</a-form-item>
			<a-form-item label="应用名称" name="name">
				<a-input v-model:value="ruleForm.name" />
			</a-form-item>
			<a-form-item label="部门" name="department">
				<SelectDepartment :default-id="ruleForm.departmentId" @change-value="setDepartment" />
			</a-form-item>
			<a-form-item label="负责人" name="handler">
				<SelectUser :parent-id="ruleForm.departmentId" :default-id="ruleForm.handler" @change-value="setHandler" />
			</a-form-item>
			<a-form-item label="国家" name="country">
				<a-select ref="select" v-model:value="ruleForm.country" style="width: 100%">
					<a-select-option v-for="item in optionsCountry" :key="item.id" :value="item.code">
						{{ item.name }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item required label="店铺ID" name="shopId" v-if="cps_shopId">
				<a-input v-model:value="ruleForm.shopId" />
				<template v-slot:extra>
					店铺ID从抖店商家后台
					<a href="https://fxg.jinritemai.com/ffa/grs/qualification/shopinfo?lh=setup"> 【店铺设置】 </a>
					-【基本信息】获取
				</template>
			</a-form-item>
			<a-form-item label="应用CODE" v-if="appCategory == 'TMS'" name="code" required>
				<a-input v-model:value="ruleForm.code" />
			</a-form-item>
			<a-form-item label="代号" v-if="cps_code" name="code">
				<a-input v-model:value="ruleForm.code" />
			</a-form-item>
			<a-form-item label="密钥" v-if="cps_accessToken" name="code">
				<a-input v-model:value="ruleForm.accessToken" />
			</a-form-item>
			<a-form-item label="appKey" v-if="cps_appKey" name="code">
				<a-input v-model:value="ruleForm.appKey" />
			</a-form-item>
			<a-form-item label="appSecret" v-if="cps_appSecret" name="code">
				<a-input v-model:value="ruleForm.appSecret" />
			</a-form-item>
			<a-form-item label="允许IP" v-if="cps_ipLimit" name="accessIpList">
				<a-textarea placeholder="请输入IP地址，用英文逗号隔开" v-model:value="ruleForm.accessIpList" />
			</a-form-item>
			<a-form-item label="应用计划">
				<a-select v-model:value="ruleForm.appPlanName" placeholder="请选择应用计划" @change="selectAppPlan">
					<a-select-option v-for="item in options" :key="item.planName">
						{{ item.planName }}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-descriptions v-if="ruleForm.appPlan" bordered style="width: 400px" :column="1" :size="'small'">
				<a-descriptions-item label="计划名称">
					{{ ruleForm.appPlan.planName }}
				</a-descriptions-item>
				<a-descriptions-item label="价格">
					{{ ruleForm.appPlan.price }}
				</a-descriptions-item>
				<a-descriptions-item label="状态">
					{{ ruleForm.appPlan.status }}
				</a-descriptions-item>
				<a-descriptions-item label="试用天数">
					{{ ruleForm.appPlan.trialDuration }}
				</a-descriptions-item>
				<a-descriptions-item label="试用次数">
					{{ ruleForm.appPlan.trialTimes }}
				</a-descriptions-item>
				<a-descriptions-item label="付费类型">
					{{ ruleForm.appPlan.payType }}
				</a-descriptions-item>
			</a-descriptions>
			<a-form-item :wrapper-col="{ span: 14, offset: 8 }">
				<a-button type="primary" style="margin: 15px auto" html-type="submit">
					{{ ruleForm.type === "edit" ? "修改" : "创建" }}
				</a-button>
			</a-form-item>
		</a-form>
	</a-card>
</template>

<script>
import SelectUser from "@/components/SelectUser.vue"
import { onActivated, reactive, toRefs, ref } from "vue"
import SelectDepartment from "@/components/SelectDepartment.vue"
// import axios from "@/common/axios";
import { useRouter, useRoute } from "vue-router"
import axios from "@/common/axios"
import api from "@/common/api/api"
import sha512 from "js-sha512"
import { message } from "ant-design-vue"
import { useStore } from "vuex"

export default {
	name: "Application",
	components: {
		SelectUser,
		SelectDepartment,
	},
	setup() {
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const isNameHave = async (type, value) => {
			let data = {}
			let idParams = state.ruleForm.id || ""
			if (type == "name") {
				data = {
					id: state.ruleForm.type === "add" ? "" : idParams,
					name: value,
					nameType: "MERCHANT_APP",
					merchantId: store.getters["user/merchantId"],
				}
			}
			console.log(state.ruleForm.type)
			//   state.ruleForm.type === "add" ? delete data.merchantId : "";
			let exist = false
			await axios.post(api.isNameHave, data).then((res) => {
				exist = res.exist
			})
			return exist
		}
		let validateName = async () => {
			if (state.ruleForm.name === "") {
				return Promise.reject("请输入商户名称")
			} else if (state.ruleForm.name !== "") {
				if (await isNameHave("name", state.ruleForm.name)) {
					return Promise.reject("当前名称重复，请重新输入")
				}
				return Promise.resolve()
			}
		}
		let validateIp = async () => {
			if (state.ruleForm.accessIpList === "") {
				return Promise.reject("请输入Ip地址")
			} else if (state.ruleForm.accessIpList !== "") {
				let isTrue = false
				state.ruleForm.accessIpList.split(",").forEach((item) => {
					if (!checkIp(item)) {
						isTrue = true
					}
				})
				if (isTrue) {
					return Promise.reject("当前IP地址不符合规范，请重新输入")
				} else {
					return Promise.resolve()
				}
			}
		}
		const state = reactive({
			rules: {
				userName: [{ required: "true", message: "请填写用户名", trigger: "blur" }],
				password: [{ required: "true", message: "请填写密码", trigger: "blur" }],
				name: [{ required: "true", validator: validateName, trigger: "blur" }],
				country: [{ required: "true", message: "请选择国家", trigger: "change" }],
				appPlanName: [{ required: "true", message: "请选择应用计划", trigger: "change" }],
				ipLimit: [{ required: "true", message: "请填选择是否限制Ip", trigger: "blur" }],
				accessIpList: [{ required: "true", validator: validateIp, trigger: "blur" }],
			},
			ruleForm: {
				appId: "",
				appPlanName: "",
				appPlanId: "",
				id: "",
				merchantId: "",
				name: "",
				appPlan: "",
				type: "",
				userName: "",
				password: "",
				icon: "",
				ipLimit: 0,
				accessIpList: "",
				accessToken: "",
				code: "",
				shopId: "",
			},
			imageUrl: "",
			loading: false,
			params: {
				code: "",
				shopId: "",
				mainAccountId: "",
				shop: "",
			},
			appCategory: "",
			optionsCountry: [],
		})
		const controlParamsShow = reactive({
			cps_code: false,
			cps_accessToken: false,
			cps_ipLimit: 0,
			cps_appKey: false,
			cps_appSecret: false,
			cps_shopId: false,
		})
		const merchantRef = ref()
		onActivated(() => {
			try {
				merchantRef.value.resetFields()
				state.ruleForm.departmentId = ""
				state.ruleForm.type = route.query.type || ""
				console.log("route.query", route.query)
				let data = {
					type: state.ruleForm.type,
					value: typeof route.query.value === "string" ? JSON.parse(route.query.value) : "",
					code: route.query.code || "",
					shopId: route.query.shopId || "",
					mainAccountId: route.query.mainAccountId || "",
					platform: route.query.platform || "",
				}

				state.params.code = route.query.code
				state.params.shopId = route.query.shopId
				state.params.mainAccountId = route.query.mainAccountId
				state.params.shop = route.query.shop

				// 初始化的时候调用
				changeAppPlanVisible(data)
				getCountry()
				state.appCategory = data.value.appCategory
			} catch {
				console.log("savgApplication")
			}
		})
		const getCountry = () => {
			axios.post(api.countryList).then((res) => {
				state.optionsCountry = res
			})
			console.log(state.ruleForm.country)
		}
		// 详情模板
		const diagleDate = reactive({
			appPlanVisible: false,
			options: [],
			OMS: false,
		})
		const checkIp = (ip) => {
			var rep =
				/^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/

			if (!rep.test(ip)) {
				return false
			} else {
				return true
			}
		}
		// 修改应用计划弹框
		const changeAppPlanVisible = ({ type, value, code }) => {
			console.log("type", type)
			console.log("value", value)
			console.log("code", code)
			switch (value.appCategory) {
				case "TMS":
					controlParamsShow.cps_appKey = true
					controlParamsShow.cps_appSecret = true
					break
				case "OMS":
					diagleDate.OMS = true
					controlParamsShow.cps_ipLimit = 1
					break
				case "HUB":
					controlParamsShow.cps_ipLimit = 1
			}
			if (value.code === "DOUYIN" || value.code === "YOUZAN") {
				//抖音店铺、有赞店铺填写shopId
				controlParamsShow.cps_shopId = true
			}
			if (type === "add" || type === "edit") {
				if (type === "edit") {
					state.ruleForm.appPlanName = value.appPlan.planName
					state.ruleForm.appPlan = value.appPlan
					state.ruleForm.appPlanId = value.appPlanId
					state.ruleForm.id = value.id
					state.ruleForm.name = value.name
					state.ruleForm.icon = value.icon
					state.ruleForm.code = value.code
					state.ruleForm.departmentId = value.departmentId ? String(value.departmentId) : ""
					state.ruleForm.handler = value.handler
					state.ruleForm.country = value.country
					state.ruleForm.accessToken = value.accessToken
					file.imageUrl = value.icon
					state.ruleForm.accessIpList = value.accessIpList ? JSON.parse(value.accessIpList).join(",") : ""
				}
				state.ruleForm.appId = value.appId
				state.ruleForm.merchantId = store.getters["user/merchantId"]
				let data = {
					appId: state.ruleForm.appId,
				}
				axios.post(api.getAppPlanList, data).then((res) => {
					diagleDate.options = res
				})
			}
			diagleDate.appPlanVisible = !diagleDate.appPlanVisible
		}
		const selectAppPlan = (value) => {
			state.ruleForm.appPlanName = value
			diagleDate.options.forEach((item) => {
				if (item.planName == value) {
					state.ruleForm.appPlan = item
					state.ruleForm.appPlanId = item.id
				}
			})
		}
		const submitAdd = () => {
			if (!state.ruleForm.name) {
				return message.warning("请输入应用名称")
			}
			if (controlParamsShow.cps_shopId && !state.ruleForm.shopId) {
				return message.warning("请输入店铺ID")
			}
			if (state.appCategory == "TMS" && !state.ruleForm.code) {
				return message.warning("请填写应用CODE")
			}
			let data = {
				merchantApp: { ...state.ruleForm, icon: state.ruleForm.icon, handler: state.ruleForm.handler },
				code: state.params.code,
				shop: state.params.shop,
				userName: diagleDate.OMS ? state.ruleForm.userName : "",
				password: diagleDate.OMS ? state.ruleForm.password || "" : "",
				thirdAppId: state.params.shopId,
				mainAccountId: state.params.mainAccountId,
				appSecret: state.ruleForm.appSecret,
				appKey: state.ruleForm.appKey,
			}
			if (controlParamsShow.cps_ipLimit === 1 && data.merchantApp.accessIpList.indexOf(",") > -1) {
				data.merchantApp.accessIpList = data.merchantApp.accessIpList.split(",")
			} else if (data.merchantApp.ipLimit === 1) {
				data.merchantApp.accessIpList = [data.merchantApp.accessIpList]
			}
			data.password = diagleDate.OMS ? sha512(data.password) : ""
			let type = state.ruleForm.type
			if (!state.ruleForm.id) {
				delete data.merchantApp.id
				delete data.merchantApp.appPlanName
				delete data.merchantApp.type
				delete data.merchantApp.appPlan
			}

			if (data.merchantApp.ipLimit === 0) {
				data.merchantApp.accessIpList = []
			}
			axios.post(api.saveMerchantApp, data).then(() => {
				if (type === "add") {
					message.success("保存成功")
				} else {
					message.success("修改成功")
				}
				router.push({ name: "applicationList" })
				changeAppPlanVisible()
			})
		}
		const backUp = () => {
			// router.back();
			state.params.code ? router.push({ name: "applicationList" }) : router.back()
		}
		const file = reactive({
			fileList: [],
			loading: false,
			imageUrl: "",
		})

		//上传功能
		const beforeUpload = (file) => {
			const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"

			if (!isJpgOrPng) {
				message.error("You can only upload JPG file!")
			}

			const isLt2M = file.size / 1024 / 1024 < 2

			if (!isLt2M) {
				message.error("Image must smaller than 2MB!")
			}

			return isJpgOrPng && isLt2M
		}
		const getBase64 = (img, callback) => {
			const reader = new FileReader()
			reader.addEventListener("load", () => callback(reader.result))
			reader.readAsDataURL(img)
		}
		const changeUpload = (info) => {
			file.loading = true
			const formData = new FormData()
			// Get this url from response in real world.
			getBase64(info.file, (base64Url) => {
				file.imageUrl = base64Url
				file.loading = false
				formData.append("file", info.file)
				formData.append("merchantId", store.getters["user/merchantId"])
				formData.append("bizType", "APP_ICON")
				axios
					.post(api.fileUpload, formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((res) => {
						state.ruleForm.icon = res.url
					})
			})
		}
		const addShipper = (type, list) => {
			addAlert[type].merchantId = store.getters["user/merchantId"]
			addAlert[list].push({ ...addAlert[type] })
			addAlert[type].externalId = ""
			addAlert[type].name = ""
			addAlert[type].code = ""
			addAlert.showShipper = false
			addAlert.showWarehouse = false
			addAlert.showShop = false
		}
		const addAlert = reactive({
			showShipper: false,
			showWarehouse: false,
			showShop: false,
			shipperList: [],
			warehouseList: [],
			shopList: [],
			shipperInfo: {
				externalId: "",
				merchantId: "",
				name: "", //货主名称
				code: "", //
				appKey: "",
				appSecret: "",
			},
			warehouseInfo: {
				externalId: "",
				merchantId: "",
				name: "", //货主名称
				code: "", //货主code
			},
			shopInfo: {
				externalId: "",
				merchantId: "",
				name: "", //货主名称
				code: "", //货主code
			},
		})
		const delshipper = (type, value) => {
			addAlert[type].forEach((item, index) => {
				if (item.name === value.name) {
					addAlert[type].splice(index, 1)
				}
			})
		}
		const getExpress = () => {
			// express/getOne
			try {
				let value = JSON.parse(route.query.value)
				let data = {
					merchantAppId: value.id,
					merchantId: store.getters["user/merchantId"],
				}
				axios.post(api.expressList, data).then((res) => {
					console.log("快递数据", res)
				})
			} catch {
				console.log("快递数据error")
			}
		}
		const setHandler = (value) => {
			state.ruleForm.handler = value.value
			console.log("handler", value)
		}
		const setDepartment = (value) => {
			console.log(value)
			state.ruleForm.departmentId = value
		}
		return {
			...toRefs(state),
			...toRefs(file),
			...toRefs(controlParamsShow),
			...toRefs(diagleDate),
			...toRefs(addAlert),
			merchantRef,
			route,
			selectAppPlan,
			setDepartment,
			setHandler,
			addShipper,
			getExpress,
			backUp,
			changeAppPlanVisible,
			isNameHave,
			submitAdd,
			delshipper,
			labelCol: { span: 5 },
			wrapperCol: { span: 14 },
			beforeUpload,
			getBase64,
			changeUpload,
		}
	},
}
</script>
<style lang="scss" scoped>
.guest-container {
	min-height: 100%;
}
.el-card.is-always-shadow {
	min-height: 100% !important;
}
.marginInfoList {
	margin: 10px 0;
	color: #6f7071;
	font-size: 14px;
	.infoLabel {
		text-align: center;
	}
	.infoValue {
		text-align: left;
	}
}
img {
	width: 100px;
	height: 100px;
}
.boxInfo {
	display: flex;
	justify-content: space-between;
	width: 400px;
	.boxShowInfo {
		border: 1px solid #d9d9d9;
		width: 291px;
		max-height: 100px;
		overflow-y: scroll;
		padding: 6px 8px 1px 8px;
		.tagMargin {
			margin-bottom: 5px;
		}
	}
}
</style>
